<template lang="pug">
b-container(fluid)
  b-row 
    b-col(cols=4)
      b-card(no-body)
        b-card-header 
          | Task
          router-link.link-icon(:to="{ name: 'task-detail-edit', params: { id: taskAttribute.TaskAttributeID } }")
            b-icon-pencil-square

        b-card-body
          table.table.table-sm.table-striped
            tbody
              tr
                td.text-nowrap Reference
                td.w-100 {{ taskAttribute.TaskReference }} 

              tr
                td.text-nowrap Created
                td.w-100 {{ taskAttribute.CreationDateTime|datetimeFormat }}

              tr
                td.text-nowrap Owner
                td.w-100 {{ taskAttribute.User.FullName }} 
                  | (
                  a(:href="`mailto:${taskAttribute.User.EMail}`") {{ taskAttribute.User.EMail }}
                  | )

              tr
                td.text-nowrap Status
                td.w-100 {{ taskAttribute.Status }} 

              tr
                td.text-nowrap Progress Description
                td.w-100 
                  template(v-if='taskAttribute.ProgressDescription') {{ taskAttribute.ProgressDescription }}
                  template(v-else) -
              
              tr(v-if='!taskAttribute.CompletionDateTime')
                td.text-nowrap Minimum due date
                td.w-100
                  template(v-if='taskAttribute.MinimumCompletionDateTime')
                    | {{ taskAttribute.MinimumCompletionDateTime|dateFormat }}
                  template(v-else-if='taskAttribute.CompletionMinimumIntervalFromParent')
                    | {{ taskAttribute.CompletionMinimumIntervalFromParent }}
                  template(v-else) Not set

              tr
                td.text-nowrap Due
                td.w-100 {{ dateFromNow(taskAttribute.CompletionDateTime) }} ({{ taskAttribute.CompletionDateTime|dateFormat }})

    b-col(cols=4)
      b-card(header='Project')
        table.table.table-sm.table-striped
          tbody
            tr
              td.text-nowrap Description
              td.w-100 {{ taskAttribute.ParentTaskAttribute.Description }} 

            tr
              td.text-nowrap Reference
              td.w-100 {{ taskAttribute.ParentTaskAttribute.TaskReference }} 

            tr
              td.text-nowrap Manager
              td.w-100 {{ taskAttribute.ParentTaskAttribute.User.FullName }} 
                | (
                a(:href="`mailto:${taskAttribute.ParentTaskAttribute.User.EMail}`") {{ taskAttribute.ParentTaskAttribute.User.EMail }}
                | )

            tr
              td.text-nowrap Status
              td.w-100 {{ taskAttribute.ParentTaskAttribute.Status }} 

            tr
              td.text-nowrap Progress Description
              td.w-100 
                template(v-if='taskAttribute.ParentTaskAttribute.ProgressDescription') 
                  | {{ taskAttribute.ParentTaskAttribute.ProgressDescription }}
                template(v-else) -            

            tr
              td.text-nowrap Due
              td.w-100 {{ dateFromNow(taskAttribute.ParentTaskAttribute.CompletionDateTime) }} ({{ taskAttribute.ParentTaskAttribute.CompletionDateTime|dateFormat }})
    
    b-col(cols=4)
      b-card(header='Quick edit')
        b-form-group(
          id="groupNotes"
          label="Shared notes"
          label-for="quickNotes"
        )
          b-form-textarea(
            v-model="sharedNotes"
            id="quickNotes"
            rows="3"
            max-rows="20"
          )

        b-button(@click='saveSharedNotes') Save

        b-form-group.mt-4(
          id="groupProgresss"
          label="Progress Description"
          label-for="quickProgress"
        )
          b-form-input(
            id="quickProgress"
            type="text"
            v-model="progressDescription"
          )
        b-button(@click='saveProgressDescription') Save

    b-col(cols=4 v-if='album')
      b-card(header='Album')
        table.table.table-sm.table-striped.mb-0
          tbody
            tr
              td.text-nowrap Title Language:
              td.w-100 {{ album.ProductDetails.TitleLanguage.Description.replace('#', '') }}
                
            tr
              td.text-nowrap Title:
              td.w-100 {{ album.ProductDetails.Title }}
                
            tr
              td.text-nowrap Version Title:
              td.w-1000 {{ album.ProductDetails.VersionTitle }} 
                
            tr
              td.text-nowrap Attribution*:
              td.w-1000 {{ album.ProductDetails.Attribution }} 
                
            tr
              td.text-nowrap Catalog number:
              td.w-1000 {{ album.CatalogNr }} 
                
            tr
              td.text-nowrap Release Date:
              td.w-1000 {{ album.ReleaseDate|dateFormat }} 
                
            tr
              td.text-nowrap Top Level:
              td.w-1000 {{ album.TopLevel }} 
                
            tr
              td.text-nowrap Recording Version:
              td.w-1000 {{ album.RecordingVersion }} 
                
            tr
              td.text-nowrap Main Genre:
              td.w-1000 {{ album.MainGenre }} 
            

    b-col(cols=8 v-if='taskAttribute.AlbumID')
      b-card(header='Tracks')
        album-track-list(:endpoint='albumTracksEndpoint')

    b-col(cols=12, v-if='false')
      b-card(header='Data')
        pre {{ taskAttribute }}
</template>

<script>

import moment from 'moment'

export default {
  components: {
    'album-track-list': () => import('@/components/AlbumTrackList.vue'),
    'tag-list': () => import('@/components/TagList.vue'),
    'contributor-list': () => import('@/components/ContributorList.vue'),
    'attribute-list': () => import('@/components/AttributeList.vue'),
  },
  props: {
    taskAttribute: Object,
  },
  data () {
    return {
      album: null,
      albumTracks: null,
      albumAttributes: null,
      sharedNotes: '',
      progressDescription: '',
    }
  },
  inject: [
    'reloadTask'
  ],
  watch: {

  },
  methods: {
    async saveSharedNotes () {
      await this.$http.put(`task-attributes/${this.taskAttribute.TaskAttributeID}/attributes/SharedNotes`,
        { value: this.sharedNotes }
      )
      this.reloadTask()
    },
    async saveProgressDescription () {
      await this.$http.put(`task-attributes/${this.taskAttribute.TaskAttributeID}/attributes/Progress Description`,
        { value: this.progressDescription }
      )
      this.reloadTask()
    },
    dateFromNow(date) {
      if (!date) return '-'
      return moment(date).fromNow()
    },
    date(date) {
      return moment(date).format()
    }, 
    async loadAlbum () {
      if (this.taskAttribute.AlbumID) {
        const response = await this.$http.get(`task-attributes/${this.taskAttribute.TaskAttributeID}/album`)
        this.album = response.data
      }
    },
    async loadAlbumAttributes () {
      if (this.taskAttribute.AlbumID) {
        this.$http
          .get(`products/${this.taskAttribute.ProductDetailsID}/attributes`)
          .then(response => {
              this.loading = false
              this.albumAttributes = response.data    
          }).catch(function () {
              this.roles = []
          })
      }
    },
  },
  computed: {
    albumTracksEndpoint () {
      return `task-attributes/${this.taskAttribute.TaskAttributeID}/album-tracks`
    }
  },
  mounted () {
    this.loadAlbum()
    this.loadAlbumAttributes()

    this.sharedNotes = this.taskAttribute.attributeTypes.SharedNotes?.Values[0].Value
    this.progressDescription = this.taskAttribute.ProgressDescription
  }
}

</script>

<style scoped>

</style>
