var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    [
                      _vm._v(" Task"),
                      _c(
                        "router-link",
                        {
                          staticClass: "link-icon",
                          attrs: {
                            to: {
                              name: "task-detail-edit",
                              params: { id: _vm.taskAttribute.TaskAttributeID }
                            }
                          }
                        },
                        [_c("b-icon-pencil-square")],
                        1
                      )
                    ],
                    1
                  ),
                  _c("b-card-body", [
                    _c(
                      "table",
                      { staticClass: "table table-sm table-striped" },
                      [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Reference")
                            ]),
                            _c("td", { staticClass: "w-100" }, [
                              _vm._v(
                                _vm._s(_vm.taskAttribute.TaskReference) + " "
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Created")
                            ]),
                            _c("td", { staticClass: "w-100" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("datetimeFormat")(
                                    _vm.taskAttribute.CreationDateTime
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Owner")
                            ]),
                            _c("td", { staticClass: "w-100" }, [
                              _vm._v(
                                _vm._s(_vm.taskAttribute.User.FullName) + " ("
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "mailto:" + _vm.taskAttribute.User.EMail
                                  }
                                },
                                [_vm._v(_vm._s(_vm.taskAttribute.User.EMail))]
                              ),
                              _vm._v(")")
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Status")
                            ]),
                            _c("td", { staticClass: "w-100" }, [
                              _vm._v(_vm._s(_vm.taskAttribute.Status) + " ")
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Progress Description")
                            ]),
                            _c(
                              "td",
                              { staticClass: "w-100" },
                              [
                                _vm.taskAttribute.ProgressDescription
                                  ? [
                                      _vm._v(
                                        _vm._s(
                                          _vm.taskAttribute.ProgressDescription
                                        )
                                      )
                                    ]
                                  : [_vm._v("-")]
                              ],
                              2
                            )
                          ]),
                          !_vm.taskAttribute.CompletionDateTime
                            ? _c("tr", [
                                _c("td", { staticClass: "text-nowrap" }, [
                                  _vm._v("Minimum due date")
                                ]),
                                _c(
                                  "td",
                                  { staticClass: "w-100" },
                                  [
                                    _vm.taskAttribute.MinimumCompletionDateTime
                                      ? [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                _vm.taskAttribute
                                                  .MinimumCompletionDateTime
                                              )
                                            )
                                          )
                                        ]
                                      : _vm.taskAttribute
                                          .CompletionMinimumIntervalFromParent
                                      ? [
                                          _vm._v(
                                            _vm._s(
                                              _vm.taskAttribute
                                                .CompletionMinimumIntervalFromParent
                                            )
                                          )
                                        ]
                                      : [_vm._v("Not set")]
                                  ],
                                  2
                                )
                              ])
                            : _vm._e(),
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Due")
                            ]),
                            _c("td", { staticClass: "w-100" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.dateFromNow(
                                    _vm.taskAttribute.CompletionDateTime
                                  )
                                ) +
                                  " (" +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.taskAttribute.CompletionDateTime
                                    )
                                  ) +
                                  ")"
                              )
                            ])
                          ])
                        ])
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c("b-card", { attrs: { header: "Project" } }, [
                _c("table", { staticClass: "table table-sm table-striped" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", { staticClass: "text-nowrap" }, [
                        _vm._v("Description")
                      ]),
                      _c("td", { staticClass: "w-100" }, [
                        _vm._v(
                          _vm._s(
                            _vm.taskAttribute.ParentTaskAttribute.Description
                          ) + " "
                        )
                      ])
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "text-nowrap" }, [
                        _vm._v("Reference")
                      ]),
                      _c("td", { staticClass: "w-100" }, [
                        _vm._v(
                          _vm._s(
                            _vm.taskAttribute.ParentTaskAttribute.TaskReference
                          ) + " "
                        )
                      ])
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "text-nowrap" }, [
                        _vm._v("Manager")
                      ]),
                      _c("td", { staticClass: "w-100" }, [
                        _vm._v(
                          _vm._s(
                            _vm.taskAttribute.ParentTaskAttribute.User.FullName
                          ) + " ("
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "mailto:" +
                                _vm.taskAttribute.ParentTaskAttribute.User.EMail
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.taskAttribute.ParentTaskAttribute.User.EMail
                              )
                            )
                          ]
                        ),
                        _vm._v(")")
                      ])
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "text-nowrap" }, [
                        _vm._v("Status")
                      ]),
                      _c("td", { staticClass: "w-100" }, [
                        _vm._v(
                          _vm._s(_vm.taskAttribute.ParentTaskAttribute.Status) +
                            " "
                        )
                      ])
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "text-nowrap" }, [
                        _vm._v("Progress Description")
                      ]),
                      _c(
                        "td",
                        { staticClass: "w-100" },
                        [
                          _vm.taskAttribute.ParentTaskAttribute
                            .ProgressDescription
                            ? [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.taskAttribute.ParentTaskAttribute
                                        .ProgressDescription
                                    )
                                )
                              ]
                            : [_vm._v("- ")]
                        ],
                        2
                      )
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "text-nowrap" }, [_vm._v("Due")]),
                      _c("td", { staticClass: "w-100" }, [
                        _vm._v(
                          _vm._s(
                            _vm.dateFromNow(
                              _vm.taskAttribute.ParentTaskAttribute
                                .CompletionDateTime
                            )
                          ) +
                            " (" +
                            _vm._s(
                              _vm._f("dateFormat")(
                                _vm.taskAttribute.ParentTaskAttribute
                                  .CompletionDateTime
                              )
                            ) +
                            ")"
                        )
                      ])
                    ])
                  ])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "b-card",
                { attrs: { header: "Quick edit" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "groupNotes",
                        label: "Shared notes",
                        "label-for": "quickNotes"
                      }
                    },
                    [
                      _c("b-form-textarea", {
                        attrs: {
                          id: "quickNotes",
                          rows: "3",
                          "max-rows": "20"
                        },
                        model: {
                          value: _vm.sharedNotes,
                          callback: function($$v) {
                            _vm.sharedNotes = $$v
                          },
                          expression: "sharedNotes"
                        }
                      })
                    ],
                    1
                  ),
                  _c("b-button", { on: { click: _vm.saveSharedNotes } }, [
                    _vm._v("Save")
                  ]),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mt-4",
                      attrs: {
                        id: "groupProgresss",
                        label: "Progress Description",
                        "label-for": "quickProgress"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: { id: "quickProgress", type: "text" },
                        model: {
                          value: _vm.progressDescription,
                          callback: function($$v) {
                            _vm.progressDescription = $$v
                          },
                          expression: "progressDescription"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    { on: { click: _vm.saveProgressDescription } },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.album
            ? _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c("b-card", { attrs: { header: "Album" } }, [
                    _c(
                      "table",
                      { staticClass: "table table-sm table-striped mb-0" },
                      [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Title Language:")
                            ]),
                            _c("td", { staticClass: "w-100" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.album.ProductDetails.TitleLanguage.Description.replace(
                                    "#",
                                    ""
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Title:")
                            ]),
                            _c("td", { staticClass: "w-100" }, [
                              _vm._v(_vm._s(_vm.album.ProductDetails.Title))
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Version Title:")
                            ]),
                            _c("td", { staticClass: "w-1000" }, [
                              _vm._v(
                                _vm._s(_vm.album.ProductDetails.VersionTitle) +
                                  " "
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Attribution*:")
                            ]),
                            _c("td", { staticClass: "w-1000" }, [
                              _vm._v(
                                _vm._s(_vm.album.ProductDetails.Attribution) +
                                  " "
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Catalog number:")
                            ]),
                            _c("td", { staticClass: "w-1000" }, [
                              _vm._v(_vm._s(_vm.album.CatalogNr) + " ")
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Release Date:")
                            ]),
                            _c("td", { staticClass: "w-1000" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(_vm.album.ReleaseDate)
                                ) + " "
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Top Level:")
                            ]),
                            _c("td", { staticClass: "w-1000" }, [
                              _vm._v(_vm._s(_vm.album.TopLevel) + " ")
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Recording Version:")
                            ]),
                            _c("td", { staticClass: "w-1000" }, [
                              _vm._v(_vm._s(_vm.album.RecordingVersion) + " ")
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v("Main Genre:")
                            ]),
                            _c("td", { staticClass: "w-1000" }, [
                              _vm._v(_vm._s(_vm.album.MainGenre) + " ")
                            ])
                          ])
                        ])
                      ]
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.taskAttribute.AlbumID
            ? _c(
                "b-col",
                { attrs: { cols: "8" } },
                [
                  _c(
                    "b-card",
                    { attrs: { header: "Tracks" } },
                    [
                      _c("album-track-list", {
                        attrs: { endpoint: _vm.albumTracksEndpoint }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          false
            ? _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("b-card", { attrs: { header: "Data" } }, [
                    _c("pre", [_vm._v(_vm._s(_vm.taskAttribute))])
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }